import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from 'src/app/shared/services/http-utils.service';
import {environment} from 'src/environments/environment';
import {CommonResponse} from "../../models/response.model";
import {PaginationModel} from "../../models/pagination.model";
import {MerchantFilterModel, MerchantModel} from "../../../modules/merchants/models/merchant.model";
import {RequestDataFilterModel} from "../../models/request-filter.model";
import {
  AccountOperationalSummary, AccountTransactionsSummary,
  FilterAccountOperationalSummary, FilterAccountSummaryModel,
  FilterAccountTransactionsSummary,
  FilterAnalyticsHeatmap
} from "../../../core/models/account.model";
import {RecentTransactionsModel} from "../../../modules/transactions/models/recent-transactions.model";
import {TransactionsTypesEnum} from "../../enums/transactions-types.enum";
import {TransactionResponseModel} from "../../../modules/transactions/models/transaction-response.model";
import {TransactionRequestFilterModel} from 'src/app/modules/transactions/models/transaction-request-filter.model';
import {TransactionRequestModel} from 'src/app/modules/transactions/models/transaction-request.model';
import {Observable} from "rxjs";
import {BankOperator, SelectedBanksOperators} from "../../../modules/configuration/models/bank-operator.model";
import {AccountConfigurationObject} from "../../../modules/partners/models/account-configuration-object.model";
import {AccountUserPreferencesObject} from "../../../modules/partners/models/account-user-preferences-object.model";
import {PolicyFilterModel} from "../../../modules/configuration/models/policy/policy-filter.model";
import {PolicyModel} from "../../../modules/configuration/models/policy/policy.model";
import {PolicyRulesModel} from "../../../modules/configuration/models/policy/policy-rules.model";
import {RefundTransactionModel} from "../../../modules/operational/components/models/refund-transaction.model";
import { HeatmapModel } from '../../models/heatmap.model';
import {DataExportOptions} from "../../../modules/export-data/models/data-export.model";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CorporateApiService {
  empty_url = '';

  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
  }

  createMerchant<T>() {
    return this.http.put<CommonResponse<PaginationModel<T>>>(this.empty_url, {});
  };

  editMerchant() {
    return this.http
      .patch<CommonResponse<PaginationModel<void>>>(this.empty_url, {});
  }

  deleteMerchant() {
    return this.http
      .delete<CommonResponse<PaginationModel<void>>>(this.empty_url);
  }

  getMerchants(filter?: RequestDataFilterModel<MerchantFilterModel>) {

    const filterParams = (!!filter) ? this.httpUtils.parseFilterParams(filter) : null;

    return this.http
      .get<CommonResponse<PaginationModel<MerchantModel>>>(this.empty_url, {
        params: filterParams
      });
  }

  getAccountSummary(accountId: string, filter?: FilterAccountSummaryModel) {
    return this.http.get<CommonResponse<number>>(this.empty_url, {
      params: {...filter}
    });
  }

  getOperationalSummary(filter?: FilterAccountOperationalSummary) {
    return this.http.get<CommonResponse<AccountOperationalSummary>>(this.empty_url, {
      params: {...filter}
    });
  }

  // Transactions

  getTransactionsResume(accountId: string, filter?: RequestDataFilterModel<any>) {
    const filterParams = this.httpUtils.parseFilterParams(filter);
    return this.http
      .get<RecentTransactionsModel>(this.empty_url, {
        params: filterParams
      });
  }

  getTransactions(type: TransactionsTypesEnum, accountId: string, filter?: RequestDataFilterModel<any>) {
    const filterParams = this.httpUtils.parseFilterParams(filter);
    return this.http
      .get<CommonResponse<PaginationModel<TransactionResponseModel>>>(this.empty_url, {
        params: filterParams
      });
  }

  getTransactionsById(transactionId: string, type?: TransactionsTypesEnum, accountId?: string) {
    return this.http
      .get<CommonResponse<TransactionResponseModel>>(this.empty_url);
  }

  syncTransaction(transactionsId) {
    return this.http
      .put<CommonResponse<string>>(this.empty_url, null);
  }

  invokeWebhook(accountId: string, transactionsId) {
    return this.http
      .put<CommonResponse<string>>(this.empty_url, null);
  }

  // Transaction Requests
  getTransactionRequests(accountId: string, filter?: TransactionRequestFilterModel) {
    return this.http.get<CommonResponse<PaginationModel<TransactionRequestModel>>>(this.empty_url);
  }

  getTransactionRequestDetails(transactionId) {
    return this.http.get<CommonResponse<TransactionRequestModel>>(this.empty_url);
  }

  reviewTransactionRequest(transactionId: string, transactionReview) {
    return this.http.patch<CommonResponse<any>>(this.empty_url, null);
  }

  getTransactionsSummary(filter?: FilterAccountTransactionsSummary) {
    return this.http.get<CommonResponse<AccountTransactionsSummary>>(this.empty_url, {
      params: filter as any
    });
  }

  getEntries(obj: RequestDataFilterModel<any>) {
    const params = this.httpUtils.parseFilterParams(obj);
    return this.http.get(this.empty_url, {
      params
    });
  }

  getPartnerEntries(obj: RequestDataFilterModel<any>, accountId: string) {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }

  getAccountSelectedBanks(accountId: number): Observable<CommonResponse<AccountConfigurationObject>> {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }

  setAccountOperationsBank(accountId: number, accountConfigurationObject: AccountConfigurationObject) {
    return this.http.put<CommonResponse<any>>(this.empty_url,
      { accountConfigurationObject }
    );
  }

  // Billing Plans

  getBillingPlans(partnerId: number) {
    const url = this.empty_url;
    return this.http.get<CommonResponse<PaginationModel<any>>>(url);
  }

  getOperationBanks(): Observable<CommonResponse<BankOperator[]>> {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }

  getSelectedBanks(): Observable<CommonResponse<SelectedBanksOperators>> {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }

  setOperationBank(pixCashIn: string[], pixCashOut: string[], forceGlobalCashIn: boolean, forceGlobalCashOut: boolean) {
    return this.http.put<CommonResponse<any>>(this.empty_url,
      { pixCashIn, pixCashOut }
    );
  }

  setAccountSecurityKey(accountId: number, accountUserPreferencesObject: AccountUserPreferencesObject) {
    let url = this.empty_url;
    return this.http.put<CommonResponse<any>>(`${url}`,
      { accountUserPreferencesObject }
    );
  }

  getAccountSecurityKey(accountId: string) {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }


  // API Access Keys
  getAccessKeys(filter: RequestDataFilterModel<any>) {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }

  reviewAccessKeys(accessKey: string, state: string, reviewNote: string) {
    return this.http.patch(this.empty_url, null);
  }

  //policy
  getPolicyRules(filter: RequestDataFilterModel<Partial<PolicyFilterModel>>) {
    let url = this.empty_url;
    return this.http.get<CommonResponse<PaginationModel<PolicyModel>>>(`${url}`, {
      params: this.httpUtils.parseFilterParams(filter)
    });
  }

  addPolicyRules(rules: PolicyRulesModel) {
    let url = this.empty_url;
    return this.http.post<CommonResponse<any>>(`${url}`, rules);
  }

  editPolicy(policy: PolicyModel, policyId: string) {
    let url = this.empty_url;
    return this.http.put<CommonResponse<any>>(`${url}`, { ...policy });
  }

  removePolicyRules(policyId: string) {
    let url = this.empty_url;
    return this.http.delete<CommonResponse<any>>(`${url}/${policyId}`);
  }

  // API Queries
  queryByCpf(cpf: string) {
    const url = this.empty_url;

    return this.http.get<CommonResponse<PaginationModel<any>>>(url);
  }

  // Operations
  getOperationalBankDocuments(filter: RequestDataFilterModel<any>) {
    const url = this.empty_url;
    return this.http.get<CommonResponse<PaginationModel<any>>>(url);
  }

  getOperationalTransactionRefund(configId: string, filter: RefundTransactionModel) {
    const url = this.empty_url;
    return this.http.put<CommonResponse<any>>(url, filter);
  }

  getOperationBankBalance(configId: string) {
    const url = this.empty_url;
    return this.http.get<CommonResponse<any>>(url);
  }

  getCashinDocumentsByTransaction(configId: string, transactionId: string) {
    const url = this.empty_url;
    return this.http.get<CommonResponse<any>>(url);
  }

  getCashoutDocumentsByTransaction(configId: string, transactionId: string) {
    const url = this.empty_url;
    return this.http.get<CommonResponse<any>>(url);
  }

  getCashinDocumentsByE2E(configId: string, filter: any) {
    const url = this.empty_url;
    return this.http.get<CommonResponse<any>>(url);
  }

  getCashoutDocumentsByE2E(configId: string, filter: any) {
    const url = this.empty_url;
    return this.http.get<CommonResponse<any>>(url);
  }

  getAccountOverview() {
    const url = this.empty_url;
    return this.http.get<CommonResponse<any>>(url);
  }

  getAuditEvents(filter: RequestDataFilterModel<any>) {
    return this.commonResponse();
  }

  getCloudLogs(filter: RequestDataFilterModel<any>) {
    return this.commonResponse();
  }

  // Data Export
  getDataExport(filter: RequestDataFilterModel<any>) {
    return this.commonResponse();
  }

  requestTransactionsExport(request: DataExportOptions) {
    return this.commonResponse();
  }

  requestAcamExport(request: DataExportOptions) {
    return this.commonResponse();
  }

  downloadDataExport(id: string) {
    return this.commonResponse();
  }

  private commonResponse() {
    const url = this.empty_url;
    return this.http.get<CommonResponse<any>>(url);
  }
  createLicense(license: any) {
    return this.http.post<CommonResponse<any>>(this.empty_url, license);
  }

  editLicense(license: any, merchantId: string) {
    return this.http.put<CommonResponse<any>>(this.empty_url, license);
  }
  deleteLicense(merchantId: string) {
    return this.http.delete<CommonResponse<any>>(this.empty_url);
  }
  getLicenses(filter?: RequestDataFilterModel<any>) {
    return this.http.get<CommonResponse<PaginationModel<any>>>(this.empty_url);
  }
  getLicenseById(merchantId: string) {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }

  getHeatmapDataCorp(filter: FilterAnalyticsHeatmap){
    let url = this.empty_url;

    const params = this.httpUtils.parseFilterParams(filter);
    return this.http.get<CommonResponse<PaginationModel<HeatmapModel>>> (url, {
      params
    });
  }
}
